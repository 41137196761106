/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onClientEntry = () => {
  console.log("--------------------------------------------")
  console.log(
    "%cMade by: Invisual Branding Solutions",
    'text-align: center; color: #006cff;font-size: 18px; font-family: "Roboto", sans-serif'
  )
  console.log(
    "%cDeveloped by: @dan_dantas",
    'color: #006cff ; font-size: 18px; font-family: "Roboto", sans-serif'
  )
  console.log("--------------------------------------------")
}
